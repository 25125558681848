/**
 * a = CY_FRONTEND_BASE_URL = "http://localhost/de.dr.test/datamanager/apiv2/"
 * b = "/datamanager/apiv2/form_element/65/background/"
 * result = "http://localhost/de.dr.test/datamanager/apiv2/form_element/65/background/"
 */
export default function concatWithoutOverlap(a, b) {
    let i = 2;

    while(!a.endsWith(b.substring(0, i))) {
        i++;
    }

    return a + b.substring(i);
}
