export default {
    nav: {
        main: {
            system: 'System'
        }
    },
    system: {
        module: {
            overview: {
                installed: 'Installed modules',
                description: 'The following list gives an overview of all the installed components and modules in this software.',
                moreInformation: 'For more information about how to enable a module, or how to develop a module, please continue reading at '
            },
            field: {
                name: {
                    label: 'Module'
                },
                translations: {
                    label: 'Translations'
                },
                routes: {
                    label: 'Routes'
                }
            }
        }
    }
}
