import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo_icon.png';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { ModuleRepository } from '_iae/module/repository';
import { userInGroup } from 'helpers/groups';

const MyLogo = () => (
  <Logo>
    <img src={ImgLogo} height="35" alt="logo" />
  </Logo>
);

@withRouter
@observer
export default class AppHeader extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
  };

  @observable debug = false;
  @observable showLogoutModal = false;

  toggleDebug = () => {
    this.debug = !this.debug;

    if (this.debug) {
      localStorage.setItem('debug', true);
    } else {
      localStorage.removeItem('debug');
    }
  }

  constructor(...args) {
    super(...args);
    this.debug = !!localStorage.getItem('debug');
  }

  hasPermission = perms => {
    return this.props.store.currentUser.hasPermission(perms);
  };

  renderOperations = () => {
    return (
      <NavMenu>
        {userInGroup(['admin', 'researcher']) && <NavItem
          title={t('nav.operations.myProjects')}
          to="/operations/project/overview"
          activePath="/operations/project/"
        />}

        {userInGroup(['admin', 'researcher', 'annotator']) && <NavItem
          title={t('nav.operations.myAssignments')}
          to="/operations/assignment/overview"
          activePath="/operations/assignment/"
        />}
      </NavMenu>
    )
  }

  renderCortex = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.cortex.files')}
          to="/cortex/file/overview"
          activePath="/cortex/file/"
        />
        <NavItem
          title={t('nav.cortex.datasets')}
          to="/cortex/dataset/overview"
          activePath="/cortex/dataset/"
        />
        <NavItem
          title={t('nav.cortex.projects')}
          to="/cortex/project/overview"
          activePath="/cortex/project/"
        />
        <NavItem
          title={t('nav.cortex.organisations')}
          to="/cortex/organisation/overview"
          activePath="/cortex/organisation/"
        />
        <NavItem
          title={t('nav.cortex.annotate')}
          to="/cortex/annotate"
          activePath="/cortex/annotate/"
        />
      </NavMenu>
    );
  };

  renderAnnotation = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.annotation.annotation')}
          to="/annotation/annotation/overview"
          activePath="/annotation/annotation/" />
        <NavItem
          title={t('nav.annotation.assignment')}
          to="/annotation/assignment/overview"
          activePath="/annotation/assignment/" />
        <NavItem
          title={t('nav.annotation.job')}
          to="/annotation/job/overview"
          activePath="/annotation/job/" />
        <NavItem
          title={t('nav.annotation.jobSpecification')}
          to="/annotation/job-specification/overview"
          activePath="/annotation/job-specification/" />
      </NavMenu>
    );
  };

  renderAssets = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.assets.users')}
          to="/assets/user/overview"
          activePath="/assets/user/"
        />
        <NavItem
          title={t('nav.assets.globalValues')}
          to="/assets/global-value/overview"
          activePath="/assets/global-value/"
        />
        <NavItem
          title={t('nav.assets.globalFiles')}
          to="/assets/global-file/overview"
          activePath="/assets/global-file/"
        />
        <NavItem
          title={t('nav.assets.emailTemplates')}
          to="/assets/email-template/overview"
          activePath="/assets/email-template/"
        />
      </NavMenu>
    );
  };

  renderAudit = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.audit.accessLog')}
          to="/audit/access-log/overview"
          activePath="/audit/access-log/"
        />
      </NavMenu>
    );
  };

  renderAccountMenu = () => {
    const { store } = this.props;
    const { version, branch } = BUILD_INFO;

    return (
      <NavItem
        title={
          <span data-test-user-details>
            <SmallAvatar user={store.currentUser} />
            {' '}
            {store.currentUser.fullName} (
            {branch && branch !== 'production' && branch + ' '}
            {version}
            )
          </span>
        }
        to="/account/details"
        activePath="/account/"
      />
    );
  };

  renderAccount = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.account.account')}
          to="/account/details"
        />
        <React.Fragment>
          <NavItem title={t('nav.account.changelog')} to="/account/changelog" />
        </React.Fragment>
      </NavMenu>
    );
  };

  render() {
    const { currentUser } = this.props.store;
    const { moduleRepository } = this.props;

    if (!this.props.store.isAuthenticated || (
      currentUser.groupNames === undefined &&
      !currentUser.isSuperuser
    )) {
      return (
        <TopMenu>
          <MenuRow>
            <MyLogo />
          </MenuRow>
          <MenuRow />
        </TopMenu>
      );
    }

    const logoutModal = (
      <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
        size='small'>
        <Header icon='archive' content={t('user.account.logoutButton')} />
        <Modal.Content>
          <p>
            {t('user.account.logout.confirm')}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
            <Icon name='remove' /> {t('form.no')}
          </Button>
          <Button color='green' inverted onClick={() => {
            this.props.store.performLogout().then(() => {
              this.showLogoutModal = false;
            });
          }}>
            <Icon name='checkmark' /> {t('form.yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

    let menu = currentUser.isSuperuser ? (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem
              title={t('nav.assets.users')}
              to="/assets/user/overview"
              activePath="/assets/user/"
            />
            <NavItem
              title={t('nav.audit.accessLog')}
              to="/audit/access-log/overview"
              activePath="/audit/access-log/"
            />
            {moduleRepository.modules.map(module => {
              return module.navigationMenu?.()?.topMenuItem;
            })}
          </NavMenu>
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/account" render={this.renderAccount} />
          {/* Hackity hack hack to always show second menu bar */}
          <NavItem title=" " to="/" activePath="/neverusethispath" />
        </MenuRow>
      </TopMenu>
    ) : (
      <TopMenu>
        <MenuRow>
          <MyLogo />

          <NavMenu>
            {userInGroup(['admin', 'researcher', 'annotator']) && <NavItem
              title={t('nav.operations.header')}
              to="/operations/project/overview"
              activePath="/operations/"
            />}
            {userInGroup(['admin']) && <NavItem
              title={t('nav.cortex.cortex')}
              to="/cortex/file/overview"
              activePath="/cortex/"
            />}
            {userInGroup(['admin']) &&
              <NavItem
                title={t('nav.annotation.annotation')}
                to="/annotation/assignment/overview"
                activePath="/annotation/"
              />}
            {userInGroup(['admin']) && <NavItem
              title={t('nav.main.assets')}
              to="/assets/user/overview"
              activePath="/assets/"
            />}
            {userInGroup(['admin']) &&
              <NavItem
                title={t('nav.audit.accessLog')}
                to="/audit/access-log/overview"
                activePath="/audit/access-log/"
              />}
          </NavMenu>
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/operations/" render={this.renderOperations} />
          <Route path="/cortex/" render={this.renderCortex} />
          <Route path="/annotation/" render={this.renderAnnotation} />
          <Route path="/account" render={this.renderAccount} />
          <Route path="/assets/" render={this.renderAssets} />
          <Route path="/audit/" render={this.renderAudit} />
        </MenuRow>
      </TopMenu>
    );

    return (
      <React.Fragment>
        {menu}
        {logoutModal}
      </React.Fragment>
    );
  }
}
