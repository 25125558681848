import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NotificationStack } from 're-cy-cle';
import { observer } from 'mobx-react';

@observer
export default class NotificationArea extends Component {
    static propTypes = {
        store: PropTypes.object,
    };
    dismiss = notification => {
        this.props.store.notifications.remove(notification);
    };
    render() {
        return (
            <NotificationStack
                notifications={this.props.store.notifications.slice()}
                onDismiss={this.dismiss}
            />
        );
    }
}
