import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { AuthenticationStore } from './Authentication';

export const APP = 'app';
export const EMAIL = 'email';
export const METHODS = [
    APP,
    EMAIL
];

export class MultiFactorAuthentication extends Model {
    static backendResourceName = 'multi_factor_authentication';

    @observable id = null;
    @observable method = EMAIL;
    @observable counter = 0;
    @observable activated = false;
    @observable authentications = this.relation(AuthenticationStore);
}

export class MultiFactorAuthenticationStore extends Store {
    Model = MultiFactorAuthentication;
    static backendResourceName = 'multi_factor_authentication';
}
