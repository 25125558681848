export default {
    nav: {
        main: {
            system: 'Systeem'
        }
    },
    system: {
        module: {
            overview: {
                installed: 'Geïnstalleerde modules',
                description: 'De volgende lijst geeft een overview van alle geïnstalleerde componenten en modules in deze software.',
                moreInformation: 'Voor meer informatie over hoe je modules aanzet of maakt, kun je verder lezen open'
            },
            field: {
                name: {
                    label: 'Module'
                },
                translations: {
                    label: 'Vertalingen'
                },
                routes: {
                    label: 'Routes'
                }
            }
        }
    }
}
