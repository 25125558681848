import { ModuleInterface, ModuleRoute } from './module';

/**
 * Keep track op all the enabled modules
 */
export class ModuleRepository {
    readonly modules: ModuleInterface[] = []

    public addModule(module: ModuleInterface) {
        this.modules.push(module)
    }

    public debug() {
        this.modules.forEach(module => {
            console.log(module.name)
        })
    }

    public getRoutes(): ModuleRoute[] {
        const combinedRoutes: ModuleRoute[] = []
        this.modules.forEach(module => {
            combinedRoutes.push(...module.routes)
        })
        return combinedRoutes
    }
}


export function configureModules(modules: ModuleInterface[]): ModuleRepository {
    const instance = new ModuleRepository()
    modules.forEach((module) => instance.addModule(module))
    return instance;
}
