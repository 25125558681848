export default {
  nav: {
    main: {
      assets: 'Stamgegevens',
    },
    operations: {
      header: 'Operaties',
      myProjects: 'Mijn projecten',
      myAssignments: 'Mijn taken',
    },
    assets: {
      users: 'Gebruikers',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere bestanden',
      emailTemplates: 'Email sjablonen',
    },
    cortex: {
      cortex: 'Cortex',
      files: 'Bestanden',
      organisations: 'Organisaties',
      datasets: 'Datasets',
      projects: 'Projecten',
      annotate: 'Annoteren',
    },
    annotation: {
      annotation: 'Annoteren',
      assignment: 'Opdrachten',
      job: 'Taken',
      jobSpecification: 'Taak specificaties',
    },
    settings: {
      title: 'Instellingen',
    },
    audit: {
      accessLog: 'Toegangslog',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    }
  },
  user: {
    overview: {
      title: 'Gebruikers',
      addButton: 'Nieuwe Gebruiker',
    },
    create: {
      title: 'Gebruiker Aanmaken',
    },
    edit: {
      title: 'Gebruiker Aanpassen',
    },
    field: {
      fullName: { label: 'Naam' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'E-mail' },
      language: { label: 'Taal' },
      password: { label: 'Wachtwoord' },
      dateJoined: { label: 'Toegevoegd Op' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Administrator',
          manager: 'Manager',
          researcher: 'Onderzoeker',
        },
        empty: 'Geen groepen toegekend',
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
      mfa: {
        label: 'MFA-methoden',
      },
    },
    login: {
      title: 'Inloggen',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'E-mail of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}',
      },
      loginButton: 'Inloggen',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText:
        'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Wachtwoord is succesvol gereset',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
    },
    changePassword: {
      title: 'Wachtwoord Aanpassen',
      field: {
        passwordOld: {
          label: 'Huidig wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?'
      },
    },
    multiFactorAuth: {
      code: {
        app: 'Verificatiecode',
        email: 'Inlogcode',
        newCode: 'Nieuwe code aanvragen',
      },
      expiring: 'Je authenticatie code is nog {{ minutes }} minuten geldig',
      explanation: {
        app: 'Voer de verificatiecode in die uw verificatie-app voor u heeft gegenereerd.',
        email: 'Uw inlogcode is per e-mail naar u verzonden.',
      },
      explanationFailed: 'We hebben geprobeerd een inlogcode naar het geregistreerde emailadres te sturen, maar dit is mislukt.',
      timeout: 'U hebt het te vaak geprobeerd. U kunt het over een paar minuten opnieuw proberen.',
      invalid: {
        app: 'De verificatiecode is verkeerd of is verlopen. Probeer het opnieuw.',
        email: 'De inlogcode is verkeerd of is verlopen. Probeer het opnieuw of vraag een nieuwe code aan.',
      },
      resend: 'Verstuur opnieuw',
      title: 'Bevestig',
    },
  },

  file: {
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Aangemaakt op' },
      name: { label: 'Naam' },
      file: { label: 'Bestand' },
    },
    overview: {
      title: 'Bestanden',
    },
    edit: {
      title: 'Bestande aanpassen',
    },
    create: {
      title: 'Bestand toevoegen',
    }
  },
  organisation: {
    overview: {
      title: 'Organisaties',
      resultsTitle: 'Organisaties',

    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      createdAt: { label: 'Aangemaakt op' },
    },
    create: {
      title: 'Organisatie toevoegen',
    }
  },
  dataset: {
    overview: {
      title: 'Datasets',
      resultsTitle: 'Datasets',
    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      project: { label: 'Project' },
      files: { label: 'Bestanden' },
      assignments: { label: 'Opdrachten' },
      createdAt: { label: 'Aangemaakt op' },
      description: { label: 'Beschrijving' },
    },
    create: {
      title: 'Dataset aanmaken',
    },
    edit: {
      title: 'Dataset aanpassen',
    }
  },
  project: {
    overview: {
      title: 'Projecten',
      resultsTitle: 'Projecten',
    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      organisation: { label: 'Organisatie' },
      users: { label: 'Gebruikers' },
      description: { label: 'Beschrijving' },
      createdAt: { label: 'Aangemaakt op' },
    },
    create: {
      title: 'Project toevoegen',
    }
  },
  zipUpload: {
    create: {
      title: 'Upload Zip File',
    }
  },
  assignment: {
    overview: {
      title: 'Opdrachten',
      resultsTitle: 'Opdrachten',
    },
    info: {
      toAssignment: 'Naar opdracht',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschrijving' },
      dataset: { label: 'Dataset' },
      status: { label: 'Status' },
      createdAt: { label: 'Aangemaakt op' },
      dueDate: { label: 'Einddatum' },
      assignees: { label: 'Toegewezen aan' },
      jobSpecifications: { label: 'Opdrachtspecificaties' },
    },
    create: {
      title: 'Opdracht aanmaken',
    },
    edit: {
      title: 'Opdracht aanpassen',
    },
    annotate: {
      backToAssignments: 'Terug naar takenlijst'
    }
  },
  job: {
    overview: {
      title: 'Taken',
      resultsTitle: 'Taken',
    },
    field: {
      id: { label: 'ID' },
      assignment: { label: 'Opdracht' },
      assignee: { label: 'Toegewezen aan' },
      file: { label: 'Bestand' },
      status: { label: 'Status' },
      createdAt: { label: 'Aangemaakt op' },
    },
    create: {
      title: 'Taak aanmaken',
    }
  },
  jobSpecification: {
    overview: {
      title: 'Taak specificaties',
      resultsTitle: 'Taak specificaties',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschrijving' },
      assignment: { label: 'Opdracht' },
      annotationType: { label: 'Annotatie Type' },
      createdAt: { label: 'Aangemaakt op' },
    },
    create: {
      title: 'Taak specificatie aanmaken',
    }
  },
  annotation: {
    overview: {
      title: 'Annotatie',
      resultsTitle: 'Annotatie',
    },
    field: {
      id: { label: 'ID' },
      annotationType: { label: 'Annotatie type' },
      annotator: { label: 'Annotator' },
      file: { label: 'Bestand' },
      createdAt: { label: 'Aangemaakt op' },
      jobDescription: { label: 'Opdrachtbeschrijving' }
    },
    create: {
      title: 'Annotatie aanmaken',
    }
  },
  filters: {
    deletedFilter: {
      label: 'Deleted',
      withoutDeleted: 'Without deleted',
      onlyDeleted: 'Only deleted',
      bothDeletedAndNot: 'Both deleted and not',
    },
  },
  globalValue: {
    edit: {
      title: 'Waarde wijzigen van algemene instelling',
    },
    overview: {
      title: 'Algemene instellingen',
    },
    field: {
      key: {
        label: 'Naam instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Bijzonder bestand bewerken',
    },
    overview: {
      title: 'Bijzondere bestanden',
    },
    field: {
      key: {
        label: 'Naam voor bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Auditlog',
    },
    field: {
      originalUser: {
        label: 'Oorspronkelijke gebruiker',
      },
      masqueradingAsUser: {
        label: 'Gemaskeerd als gebruiker',
      },
      ipAddress: {
        label: 'IP-adres',
      },
      method: {
        label: 'HTTP-methode',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Statuscode',
      },
      additionalInfo: {
        label: 'Extra informatie',
      },
      requestTime: {
        label: 'Tijdstip van toegang',
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    view: 'Bekijk',
    restore: 'Herstel',
  },
  form: {
    repeat: 'Herhaal',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload',
    },
    saveButton: 'Opslaan',
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion:
        'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    submitButton: 'Verstuur',
    cancelButton: 'Annuleren',
    addButton: 'Toevoegen',
    deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
    restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
    startDate: 'Start Datum',
    endDate: 'Eind Datum',
    no: 'Nee',
    yes: 'Ja',
    either: 'Beide',
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina'
  },
  emailTemplate: {
    overview: {
      title: 'Email sjablonen',
      addButton: 'Nieuw sjabloon',
    },
    create: { title: 'Mail sjabloon aanmaken' },
    edit: { title: 'Mail sjabloon aanpassen' },
    add: { title: 'Mail sjabloon toevoegen' },
    field: {
      id: { label: 'ID' },
      isDefault: { label: 'Is standaard' },
      name: { label: 'Naam' },
      emailType: { label: 'Email type' },
      subject: { label: 'Onderwerp' },
      textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
      senderEmail: { label: 'Verzender' },
    }
  },
  emailTypes: {
    password_reset: 'Wachtwoord reset',
    user_activation: 'Account activatie',
  },
  authentication: {
    dates: {
      maxDate: {
        placeholder: 'Max date'
      },
      minDate: {
        placeholder: 'Min date'
      },
      month: {
        0: 'January',
        1: 'February',
        10: 'November',
        11: 'December',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October'
      },
      weekDayShort: {
        friday: 'Fr',
        monday: 'Mo',
        saturday: 'Sa',
        sunday: 'Su',
        thursday: 'Th',
        tuesday: 'Tu',
        wednesday: 'We'
      }
    },
    field: {
      mfa: {
        label: 'Methode',
      },
      code: {
        label: 'Code'
      },
      expiresAt: {
        label: 'Verloopt Op'
      },
      ipAddress: {
        label: 'IP-adres'
      },
      status: {
        label: 'Status',
        value: {
          expired: 'Verlopen',
          verified: 'Geverifieerd',
          waiting: 'In behandeling'
        }
      },
      userAgent: {
        label: 'User Agent'
      }
    }
  },
  multiFactorAuthentication: {
    setup: {
      title: 'Multistapsverificatie instellen',
      step1: {
        title: '1. Download de app',
        description: 'Download een verificatie-app, zoals Google Authenticator, als u deze nog niet heeft.',
      },
      step2: {
        title: '2. Scan de QR-code',
        description: 'Scan deze QR-code met uw verificatie-app.',
      },
      step3: {
        title: '3. Voer uw code in',
        description: 'Voer hier de bevestigingscode in die u ziet in uw verificatie-app.',
      },
      subtitle: 'Na het instellen kunt u met uw verificatie-app altijd veilig inloggen.',
    },
    field: {
      method: {
        label: 'MFA-methode',
        options: {
          app: 'Verificatie-app',
          email: 'E-mail'
        }
      },
      counter: {
        label: 'Gebruik',
      },
      activated: {
        label: 'Geverifieerd',
      },
    }
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
  },
  pageNotFoundMessage: 'Pagina niet gevonden',
  brandingMessage: 'Powered by Theta Vision',
};
