import React from 'react';
import L from 'react-loadable';
import { PageLoader } from 'component/Loader';

const Loadable = loader =>
    L({
        delay: 500,
        loader,
        loading(props) {
            if (props.error) {
                throw props.error;
            }

            return (
                <PageLoader show/>
            );
        }
    });

export default Loadable;
