import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';
import { ModuleRepository } from '_iae/module/repository';
import { LoginForgot, ResetPassword, ViewStore } from '@code-yellow/spider';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const EmailTemplateOverview = Load(() => import('../screen/EmailTemplates/Overview'));
const EmailTemplateEdit = Load(() => import('../screen/EmailTemplates/Edit'));

const FileOverview = Load(() => import('../screen/Cortex/File/FileOverview'));
const OrganisationOverview = Load(() => import('../screen/Cortex/Organisation/OrganisationOverview'));
const ProjectOverview = Load(() => import('../screen/Cortex/Project/ProjectOverview'));
const DatasetOverview = Load(() => import('../screen/Cortex/Dataset/DatasetAdminOverview'));


const AssignmentOverview = Load(() => import('../screen/Annotation/Assignment/AssignmentOverview'));
const JobOverview = Load(() => import('../screen/Annotation/Job/JobOverview'));
const JobSpecificationOverview = Load(() => import('../screen/Annotation/JobSpecification/JobSpecificationOverview'));
const AnnotationOverview = Load(() => import('../screen/Annotation/Annotation/AnnotationOverview'));

const ProjectManagementScreen = Load(() => import('../screen/Cortex/Project/MyProjects'));
const MyAssignmentsScreen = Load(() => import('../screen/Annotation/Assignment/MyassignmentsScreen.tsx'));


const AnnotateScreen = Load(() => import('screen/Annotation/Assignment/AssignmentAnnotation'));
const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(ViewStore).isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
  };

  // react-router is a bit too verbose so I made a shorthand
  route = Screen => {
    const { moduleRepository } = this.props;
    return rProps => <Screen {...rProps} viewStore={this.props.store} moduleRepository={moduleRepository} />;
  };

  redirectFromHome = () => {
    const { currentUser } = this.props.store;

    let uri = '/account/details';

    if (currentUser.isSuperuser) {
      uri = '/assets/user/overview'
    }

    if (currentUser.inGroup('annotator')) {
      uri = '/operations/assignment/overview'
    }


    return <Redirect to={uri}/>;
  };

  render() {
    const { moduleRepository } = this.props;

    return (
      <Switch>
        <Route path="/" exact render={this.redirectFromHome} />

        <Route path="/assets/user/overview" render={this.route(UserOverview)} />
        <Route path="/assets/user/:id/edit" render={this.route(UserEdit)} />
        <Route path="/assets/user/add" render={this.route(UserEdit)} />
        <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)} />
f
        <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)} />
        <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

        <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)} />
        <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)} />

        <Route path="/assets/email-template/overview" render={this.route(EmailTemplateOverview)} />
        <Route path="/assets/email-template/add" render={this.route(EmailTemplateEdit)} />
        <Route path="/assets/email-template/:id/edit" render={this.route(EmailTemplateEdit)} />

        <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)} />

        <Route path="/account/details" render={this.route(AccountDetails)} />
        <Route path="/account/changelog" render={this.route(Changelog)} />
        <Route path="/login/forgot" render={this.route(LoginForgot)} />
        <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />


        <Route path="/cortex/file/overview" render={this.route(FileOverview)} />
        <Route path="/cortex/organisation/overview" render={this.route(OrganisationOverview)} />
        <Route path="/cortex/project/overview" render={this.route(ProjectOverview)} />
        <Route path="/cortex/dataset/overview" render={this.route(DatasetOverview)} />


        <Route path="/annotation/assignment/overview" render={this.route(AssignmentOverview)} />
        <Route path="/annotation/job/overview" render={this.route(JobOverview)} />
        <Route path="/annotation/job-specification/overview" render={this.route(JobSpecificationOverview)} />
        <Route path="/annotation/annotation/overview" render={this.route(AnnotationOverview)} />



        <Route path="/operations/project/overview" render={this.route(ProjectManagementScreen)} />
        <Route path="/operations/assignment/overview" render={this.route(MyAssignmentsScreen)} />
        <Route path="/operations/assignment/:id/annotate" render={this.route(AnnotateScreen)} />

        {moduleRepository.getRoutes().map(moduleRoute => {
          return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)} />
        })}

        <Route render={this.route(NotFound)} />
      </Switch>
    );
  }
}
