import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Container, Message } from 'semantic-ui-react';
import { TargetTextInput, CancelButton, RightDivider } from '@code-yellow/spider';
import styled from 'styled-components';

import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AuthenticationStore } from '../store/Authentication';

/**************** COMPONENTS *******************/

const MultiFactorAuthConfirmModalHeader = styled.h3`
    color: #48b4ca;
    font-size: 20px;
    width: 100%;
    text-align: center;
`;

const QRCodeContainer = styled(Container)`
    padding-top: 25px;
`;

const QRCode = styled.img`
    margin: auto;
    display: block;
    max-width: 200px;
`;

const SmallTextInput = styled(TargetTextInput)`
     .fluid.fluid.fluid.fluid.fluid  {
        width: 120px !important;
     }
`;

const SecretCode = styled.div`
    color: grey;
    font-size: 12px;
    width: 100%;
    text-align: center;
`

/**************** Layout *******************/

const MultiFactorAuthConfirmContainer = styled.div`
    margin-right: 32px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
`;

const MultiFactorAuthConfirmDetailsContainer = styled(Form)`
    margin-top: 12px;
    & h3 {
        text-align: left;
        margin-bottom: 20px;
    }
`;

const MultiFactorAuthConfirmDetailsColumnsContainer = styled.div`
    display: flex;
    flex: 1;
`;

const MultiFactorAuthConfirmDetailsColumn = styled.div`

    margin-left: 32px;
    width: 270px;
    ${props => props.border ? `
        border-right: 1px solid #E0E0E0;
        padding-right: 32px;
    ` : ''}
`;

const MultiFactorAuthConfirmDetailsBottom = styled.div`
    margin-top: 24px;
    margin-left: 32px;
`;

@observer
export default class MultiFactorAuthConfirmModal extends Component {
    static propTypes = {
        viewStore: PropTypes.object
    };

    secret = '';
    @observable code = '';
    @observable qr = null;
    @observable hasError = false;

    handleCodeChange = (value) => this.code = value;

    handleSubmit = () => {
        const { viewStore } = this.props;
        return (
            new AuthenticationStore()
            .verifyCode(this.code, this.secret)
            .then(() => {
                this.hasError = false;
                viewStore.fetchBootstrap();
            })
            .catch(err => {
                this.hasError = true;
                this.code = '';
            })
        );
    };

    handleCancel = () => {
        const { viewStore } = this.props;
        viewStore.auth.activated = true;
        viewStore.performLogout();
    };

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const authStore = new AuthenticationStore();
        authStore.generateSecret().then(async res => {
            this.secret = res['secret_key'];
            const qrRes = await authStore.generateQr(this.secret);
            this.qr = URL.createObjectURL(qrRes);
        });
    }

    render() {
        return (
            <Modal
                open={true}
            >
                <Modal.Header>{t('multiFactorAuthentication.setup.title')}</Modal.Header>
                <Modal.Content>

                    <MultiFactorAuthConfirmContainer>
                        <MultiFactorAuthConfirmDetailsContainer>

                            <MultiFactorAuthConfirmDetailsColumnsContainer>
                                <MultiFactorAuthConfirmDetailsColumn border>
                                    <MultiFactorAuthConfirmModalHeader>{t('multiFactorAuthentication.setup.step1.title')}</MultiFactorAuthConfirmModalHeader>
                                    <p>{t('multiFactorAuthentication.setup.step1.description')}</p>
                                </MultiFactorAuthConfirmDetailsColumn>
                                <MultiFactorAuthConfirmDetailsColumn border>
                                    <MultiFactorAuthConfirmModalHeader>{t('multiFactorAuthentication.setup.step2.title')}</MultiFactorAuthConfirmModalHeader>
                                    <p>{t('multiFactorAuthentication.setup.step2.description')}</p>

                                    <QRCodeContainer>
                                        {this.qr && (
                                            <QRCode
                                                data-test-totp-secret={this.secret}
                                                src={this.qr}
                                                alt="qr-code"
                                            />
                                        )}
                                    </QRCodeContainer>
                                    <SecretCode>{this.secret}</SecretCode>
                                </MultiFactorAuthConfirmDetailsColumn>
                                <MultiFactorAuthConfirmDetailsColumn>
                                    <MultiFactorAuthConfirmModalHeader>{t('multiFactorAuthentication.setup.step3.title')}</MultiFactorAuthConfirmModalHeader>
                                    <p>{t('multiFactorAuthentication.setup.step3.description')}</p>
                                    <SmallTextInput fluid
                                                    name="mfa-code"
                                                    type="numeric_input"
                                                    value={this.code}
                                                    onChange={this.handleCodeChange}
                                                    autoFocus={true}
                                                    placeholder="Code"
                                    />
                                    {
                                        this.hasError && <Message data-test-mfa-invalid error content={t(`user.multiFactorAuth.invalid.${this.props.viewStore.auth.method}`)} style={{ 'display': 'block' }}/>
                                    }
                                </MultiFactorAuthConfirmDetailsColumn>
                            </MultiFactorAuthConfirmDetailsColumnsContainer>


                            <MultiFactorAuthConfirmDetailsBottom>
                                <p>{t('multiFactorAuthentication.setup.subtitle')}</p>
                            </MultiFactorAuthConfirmDetailsBottom>


                        </MultiFactorAuthConfirmDetailsContainer>

                    </MultiFactorAuthConfirmContainer>

                </Modal.Content>
                <Modal.Actions>
                    <RightDivider/>
                    <CancelButton data-test-mfa-cancel onClick={this.handleCancel}/>
                    <Button data-test-mfa-submit
                            icon="check"
                            primary
                            labelPosition="left"
                            content={t('form.submitButton')}
                            onClick={this.handleSubmit}
                            disabled={this.code.length !== 6}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

}
