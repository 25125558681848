import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { lighten } from 'polished';
import TrippyBackground from 'component/TrippyBackground';
import { Body, Sidebar, ContentContainer, FormField, TextInput, Col, Row } from 're-cy-cle';
import { AuthenticationStore } from 'store/Authentication';
import logo from 'image/logo_icon.png';
import { Button, Message } from 'semantic-ui-react';
import { Image, Header, Form } from 'semantic-ui-react'
import MultiFactorAuthConfirmModal from 'container/MultiFactorAuthConfirmModal';
import { Link } from '@code-yellow/spider'

export const ResetCodeLink = styled(Link)`
    color: teal !important;
    float: right;
    margin-right: 10%;
    padding-bottom:10px;
    &:hover {
        color: ${lighten(0.2, 'teal')} !important;
    }
    font-weight: bold;
`;

@observer
 class MultiFactorAuth extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
    };

    @observable code = '';
    @observable hasError = false;
    @observable timeOut = false;

    authenticationStore = new AuthenticationStore();

    handleCodeChange = (name, value) => this.code = value;

    handleSubmit = () => {
        if(this.authenticationStore.isLoading) {
            return;
        }

        const { viewStore } = this.props;
        return (
            this.authenticationStore
            .verifyCode(this.code)
            .then(async () => {
                this.hasError = false;
                await viewStore.fetchBootstrap();
                viewStore.isNenCompliant = !viewStore.currentUser.isSuperuser;
            })
            .catch(err => {
                this.hasError = true;
                this.timeOut = err.response.data && err.response.data.message === 'Max attempts exceeded';
            })
        );
    };

    handleReset = () => {
        if(this.authenticationStore.isLoading) {
            return;
        }

        const { viewStore } = this.props;
        return (
            this.authenticationStore
            .resetCode()
            .then(() => viewStore.fetchBootstrap())
        );
    };

    handleCancel = () => {
        const { viewStore } = this.props;
        return viewStore.performLogout();
    };

    render() {
        const { viewStore } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground>
                        <div style={{ flex: '1' }}/>
                        <Sidebar>
                            <a href="/">
                                <Image style={{ 'margin': 'auto', 'marginTop': '10%' }} size='tiny' src={logo} />
                            </a>
                        {viewStore.auth?.activated ? <Form>
                            <Header as='h2'>{t('user.multiFactorAuth.title')}</Header>
                            <p>{
                                viewStore.auth.status === 'waiting'
                                ? t(`user.multiFactorAuth.explanation.${viewStore.auth.method}`)
                                : t('user.multiFactorAuth.explanationFailed')
                            }</p>
                            {viewStore.auth.status === 'waiting' && (
                                <React.Fragment>
                                    <FormField>
                                        <label>{t(`user.multiFactorAuth.code.${viewStore.auth.method}`)}</label>
                                        <TextInput
                                            name="mfa-code"
                                            type="numeric_input"
                                            value={this.code}
                                            onChange={this.handleCodeChange}
                                            autoFocus={true}
                                        />
                                    </FormField>
                                </React.Fragment>
                            )}
                            {
                                this.hasError && (this.timeOut ? <Message data-test-mfa-maxattempts error content={t('user.multiFactorAuth.timeout')} style={{ 'display': 'block' }}/> :
                                <Message data-test-mfa-invalid error content={t(`user.multiFactorAuth.invalid.${viewStore.auth.method}`)} style={{ 'display': 'block' }}/>)
                            }

                            <Row>
                                {viewStore.auth.method !== 'app' && (<div style={{ 'width': '100%' }}>
                                        <ResetCodeLink  data-test-mfa-reset to='#' onClick={this.handleReset}>{t('user.multiFactorAuth.code.newCode')}</ResetCodeLink>
                                    </div>)}
                                {viewStore.auth.status === 'waiting' && (
                                    <Col xs>
                                        <Button loading={this.authenticationStore.isLoading} data-test-mfa-submit primary fluid content={t('form.submitButton')} onClick={this.handleSubmit} disabled={this.code.length !== 6}/>
                                    </Col>
                                )}
                                <Col xs>
                                    <Button loading={this.authenticationStore.isLoading} type="button" data-test-mfa-cancel secondary fluid content={t('form.cancelButton')} onClick={this.handleCancel} />
                                </Col>
                            </Row>
                        </Form> : <MultiFactorAuthConfirmModal viewStore={viewStore}/>}
                        <div style={{ flexGrow: '1' }}/>
                        </Sidebar>
                    </TrippyBackground>
                </ContentContainer>
            </Body>
        );
    }
}
export default MultiFactorAuth
